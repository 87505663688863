import { FormControl } from "@angular/forms";

export class CustomValidator {

    public static letters = {
        "0": 0, "1": 1, "2": 2, "3": 3, "4": 4,
        "5": 5, "6": 6, "7": 7, "8": 8, "9": 9,
        A: 10, J: 18, S: 26,
        B: 11, K: 19, T: 27,
        C: 12, L: 20, U: 28,
        D: 13, M: 21, V: 29,
        E: 14, N: 22, W: 32,
        F: 15, O: 35, X: 30,
        G: 16, P: 23, Y: 31,
        H: 17, Q: 24, Z: 33,
        I: 34, R: 25
    };

    /** 驗證身分證檢查碼，FormControl 用 */
    public static validLastCode(e: FormControl) {
        if (CustomValidator.valudIDNumber(e.value))
            return null;
        else
            return { isValid: false };
    }

    public static valudIDNumber(target) {
        if (target && target.length === 10 && target.match(/^[A-Z]{1}[A-D]{1}[0-9]{8}$|^[A-Z]{1}[8-9]{1}[0-9]{8}$|^[A-Z]{1}[12]{1}[0-9]{8}$/)) {
            let sum = 0;
            const charList = [...target];
            sum += Math.floor(CustomValidator.letters[charList[0]] / 10);
            for (let i = 0; i < 9; i++) {
                sum += CustomValidator.letters[charList[i]] * (9 - i);
            }

            const answer = (10 - (sum % 10)) % 10;

            return +charList[9] === answer ? true : false;
        } else {
            return false;
        }
    }
}