<h5 class="pstitle mt-1 mb-3">請輸入您個人的聯繫資訊以取得驗證碼</h5>
<ng-container [formGroup]="validWayGroup">
    <mat-radio-group class="d-flex flex-column"
                     color="primary"
                     [attr.disabled]="isLoading?'disabled':null"
                     [formControlName]="'way'">
        <mat-radio-button [value]="'email'">
            電子郵件信箱
        </mat-radio-button>
        <div class="form-group ml-4">
            <input type="email"
                   class="form-control"
                   [formControlName]="'email'"
                   [matAutocomplete]="auto"
                   [attr.disabled]="isLoading?'disabled':null"
                   placeholder="信箱帳號">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of emailOptions"
                            [value]="option">
                    {{option}}
                </mat-option>
            </mat-autocomplete>
        </div>
        <mat-radio-button [value]="'phone'">
            行動電話
        </mat-radio-button>
        <div class="form-group ml-4">
            <input type="text"
                   class="form-control"
                   [formControlName]="'phone'"
                   [attr.disabled]="isLoading?'disabled':null"
                   placeholder="電話號碼"
                   autocomplete="off">
        </div>
    </mat-radio-group>
    <div class="step-action mt-3">
        <button *ngIf="!timerIsStart;else tplTimer"
                type="button"
                class="btn btn-custom btn-lg"
                [disabled]="!validWayGroup.valid || isLoading"
                (click)="sendCode()">
            {{isLoading ? '驗證碼發送中...' : '取得驗證碼'}}
        </button>
        <ng-template #tplTimer>
            <button type="button"
                    class="btn btn-count-down btn-lg">
                重新取得 {{countDownText}}
            </button>
        </ng-template>
        <div *ngIf="isError">
            <mat-error>{{errMsg}}</mat-error>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="validCodeSended">
    <h5 class="pstitle mt-5 mb-2">請輸入驗證碼</h5>
    <div class="form-group mt-2">
        <p class="lb-description">
            驗證碼時效為1小時，使用進入後，此驗證碼失效。
        </p>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <ng-otp-input #ngOtpInput
                          (onInputChange)="onCodeChanged($event)"
                          [config]="otpInputConfig">
            </ng-otp-input>
        </div>
    </div>
    <div class="step-action"
         style="margin-top: 116px;">
        <button type="button"
                class="btn btn-custom btn-lg"
                [disabled]="!codeInputCompleted"
                (click)="checkValidCode()">
            確認
        </button>
        <div *ngIf="invalidCode||isError2">
            <mat-error class="mt-2"
                       *ngIf="invalidCode">無效的驗證碼</mat-error>
            <mat-error *ngIf="isError2">{{errMsg2}}</mat-error>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="emailAlreadyExist">
    <h5 class="pstitle mt-1 mb-3">帳號已建立</h5>
    <p>
        您輸入的電子信箱已建立單一身分驗證帳號。
        如您要增加綁定子女，請立即登入，於帳號設定中增加綁定。
    </p>
    <div class="step-action">
        <button type="button"
                class="btn btn-custom btn-lg"
                (click)="login()">立即登入</button>
    </div>
</ng-container>