import { Injectable, NgZone } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { SetMember, SetEmail, SetPhone, SetIDNumber, Reset } from './member.action';
import { Router } from '@angular/router';

@State({
    name: 'member',
    defaults: {
        id: null,
        email: null,
        phone: null,
        idNumber: null
    } as MemberModel
})
@Injectable()
export class MemberState {

    constructor(
        private router: Router,
        private ngZone: NgZone
    ) {}

    @Action(SetMember)
    setMember(ctx: StateContext<MemberModel>, action: SetMember) {
        ctx.setState(action.member);
    }

    @Action(SetEmail)
    setEmail(ctx: StateContext<MemberModel>, action: SetEmail) {
        ctx.patchState({email: action.email});
    }

    @Action(SetPhone)
    setPhone(ctx: StateContext<MemberModel>, action: SetPhone) {
        ctx.patchState({phone: action.phone});
    }

    @Action(SetIDNumber)
    setIDNumber(ctx: StateContext<MemberModel>, action: SetIDNumber) {
        ctx.patchState({idNumber: action.idNumber});
    }

    @Action(Reset)
    reset(ctx: StateContext<MemberModel>, action: Reset) {
        // 清空 state
        ctx.setState({email: null, phone: null, idNumber: null});
        // 畫面回到首頁
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.ngZone.run(() => this.router.navigate(['/'])).then();
    }

}

export interface MemberModel {
    email: string;
    phone: string;
    idNumber: string;
}