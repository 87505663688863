import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLoadingComponent } from './app-loading/app-loading.component';
import { AppStartComponent } from './app-start/app-start.component';
import { AppComponent } from './app.component';
import { ApplicationComponent } from './application/application.component';
import { Step1Component } from './application/step1/step1.component';
import { Step2Component } from './application/step2/step2.component';
import { Step3Component } from './application/step3/step3.component';
import { Step4Component } from './application/step4/step4.component';
import { BoardComponent } from './board/board.component';

const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full' },
  { path: 'apply', redirectTo: 'start', pathMatch: 'full' },
  
  { path: 'start', component: AppStartComponent },
  { path: 'loading', component: AppLoadingComponent },
  { path: 'board', component: BoardComponent },
  {
    path: 'apply', component: ApplicationComponent,
    children: [
      { path: '1', component: Step1Component },
      { path: '2', component: Step2Component },
      { path: '3', component: Step3Component },
      { path: '4', component: Step4Component }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
