import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';

// ngxs
import { NgxsModule } from '@ngxs/store';
import { MemberState } from './state/member.state';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Step1Component } from './application/step1/step1.component';
import { Step2Component } from './application/step2/step2.component';
import { Step3Component } from './application/step3/step3.component';
import { Step4Component } from './application/step4/step4.component';
import { BoardComponent } from './board/board.component';
import { ApplicationComponent } from './application/application.component';
import { AppLoadingComponent } from './app-loading/app-loading.component';
import { AppStartComponent } from './app-start/app-start.component';

@NgModule({
  declarations: [
    AppComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    BoardComponent,
    ApplicationComponent,
    AppLoadingComponent,
    AppStartComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxsModule.forRoot([MemberState]),
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
