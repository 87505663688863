import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PcbindService } from '../../core/pcbind.service';
import { Store } from '@ngxs/store';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Reset } from '../../state/member.action';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ApplicationComponent } from '../application.component';
import { isThisTypeNode } from 'typescript';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit {

  @Output() validChange = new EventEmitter<boolean>();
  memberID: number;
  agreementControl = new FormControl(null, [Validators.required, Validators.requiredTrue]);

  constructor(
    private router: Router,
    private pcbindSrv: PcbindService,
    private dialog: MatDialog,
    private store: Store,
    private http: HttpClient,
    private appComponent: AppComponent,
    private applicationComponent: ApplicationComponent
  ) { }

  async ngOnInit() {
    await this.applicationComponent.ready();
    await this.applicationComponent.setStep(3);

    await this.appComponent.ready();
    console.log(this.appComponent.userInfo.agreed);
    this.agreementControl.setValue(this.appComponent.userInfo.agreed);
  }

  async confirm() {
    // const { success } = await this.pcbindSrv.setAgreement() as any;
    let setAgreementResp: any;
    try {
      setAgreementResp = await this.http.post('/service/set_agreement', {}).toPromise();
    }
    catch (error) {
      alert("簽署同意失敗");
      return;
    }
    const { success } = setAgreementResp;
    if (success) {
      this.appComponent.userInfo.agreed = true;
      if (this.appComponent.tempList.length > 0)
        this.router.navigate(['/', 'apply', '4']);
    }
  }

  disagree() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: '尚未完成綁定，現在離開將會遺失已填寫資料。是否離開？'
      }
    });

    dialogRef.afterClosed().pipe(
      take(1)
    ).subscribe(v => {
      if (v) {
        this.router.navigate(['/', 'start']);
      }
    });
  }

}
