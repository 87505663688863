import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { MessageDialogComponent } from './shared/message-dialog/message-dialog.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    userInfo: any = null;
    applyList: any[] = null;
    tempList: any[] = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private dialog: MatDialog
    ) { }

    async ngOnInit() {
        // userInfo
        await this.reloadUserInfo();
        // applyList
        await this.reloadApplyList();
        // 載入完成
        this.readyCallBack.forEach(resolve => { resolve(); });
        this.readyCallBack = [];
    }

    private readyCallBack: any[] = [];
    ready() {
        return new Promise<void>(resolve => {
            if (this.userInfo && this.applyList) resolve();
            else {
                this.readyCallBack.push(resolve);
            }
        });
    }

    async reloadUserInfo() {
        let reloadApplyList = false;
        try {
            const userInfoRsp: any = await this.http.get(`/service/get_user_info`).toPromise();
            // 如果不適第一次讀取，並且讀取後的身分正號有變，會主動重載申請紀錄
            if (this.userInfo && this.userInfo.idNumber != userInfoRsp.idNumber)
                reloadApplyList = true;
            this.userInfo = userInfoRsp;
        }
        catch (error) {
            this.dialog.open(MessageDialogComponent, { data: { title: '發生錯誤', message: '主機連線發生錯誤' } });
            throw error;
        }
        if (reloadApplyList) {
            this.applyList = null;
            await this.reloadApplyList();
        }
    }

    async reloadApplyList() {
        let relationRsp: any, tempRsp: any;
        try {
            relationRsp = await this.http.get(`/api/imdb/get_relationships`).toPromise();
            if (!relationRsp.success)
                throw relationRsp;
        }
        catch (error) {
            this.dialog.open(MessageDialogComponent, { data: { title: '發生錯誤', message: '查詢已申請子女清單發生錯誤' } });
            console.log(error);
            return;
        }

        try {
            tempRsp = await this.http.get(`/service/getUnfinishedApply`).toPromise();
        }
        catch (error) {
            this.dialog.open(MessageDialogComponent, { data: { title: '發生錯誤', message: '查詢暫存子女清單發生錯誤' } });
            console.log(error);
            return;
        }

        this.applyList = relationRsp.data;
        this.tempList = [].concat(tempRsp || [])
            // 同身分證留一筆
            .filter((value, index, list) => list.findIndex(item => item.id_number == value.id_number) == index)
            // 已經申請過不留
            .filter(value => this.applyList.findIndex(item => item.StudentIDNumber == value.id_number) < 0)
            ;
        console.log(this.tempList);
    }
}
