import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-app-loading',
  templateUrl: './app-loading.component.html',
  styleUrls: ['./app-loading.component.scss'],
})
export class AppLoadingComponent implements OnInit {
  get isApp() {
    //@ts-ignore
    return !!window.ReactNativeWebView;
  }

  constructor(
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    await this.appComponent.ready();

    this.route.queryParams.subscribe((params) => {
      const isFirst = params['isFirst'] === '1';

      console.log('isFirst', isFirst);

      // 取得當前使用者角色，如果有學生，或是第一次進入，則轉導至首頁
      const roles = this.appComponent.userInfo?.roles;
      if (
        (Array.isArray(roles) &&
          roles.length > 0 &&
          roles.includes('student')) ||
        isFirst
      ) {
        this.close();
        return;
      }

      if (this.appComponent.applyList.length > 0) {
        this.router.navigate(['/', 'board']);
        return;
      }

      if (
        this.appComponent.userInfo.idNumber ||
        this.appComponent.userInfo.email ||
        this.appComponent.userInfo.phone
      ) {
        this.router.navigate(['/', 'apply', '2']);
        return;
      }

      this.router.navigate(['/', 'apply', '1']);
    });
  }

  close() {
    if (this.isApp) {
      window.location.replace('https://cooc.tp.edu.tw/app/close');
      return;
    }
    window.location.replace(
      '/service/logout?next=' + encodeURIComponent('https://cooc.tp.edu.tw/')
    );
  }
}
