import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PcbindService } from '../../core/pcbind.service';
import { Store } from '@ngxs/store';
import { SetMember } from '../../state/member.action';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { HttpClient } from '@angular/common/http';
import { ApplicationComponent } from '../application.component';

@Component({
    selector: 'app-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit, OnDestroy {

    @ViewChild('ngOtpInput') ngOtpInputRef: any;
    @Output() validChange = new EventEmitter<boolean>();

    isLoading: boolean;
    validWayGroup = new FormGroup({
        'way': new FormControl(null, [Validators.required]),
        'email': new FormControl(null),
        'phone': new FormControl(null)
    });
    emailOptions: string[] = [];
    // 檢查 email 是否已存在 LDAP 帳號
    emailAlreadyExist: boolean = false;
    otpInputConfig = {
        length: 6,
        allowNumbersOnly: true,
        inputStyles: {
            'width': '14%',
            'height': '60px',
            'font-size': '1rem'
        }
    };
    // 驗證碼
    code = ''
    // 驗證碼是否發送成功
    validCodeSended = false;
    // 驗證碼是否輸入完成
    codeInputCompleted = false;
    // 驗證碼是否驗證成功
    invalidCode: boolean = false;
    timerIsStart: boolean;
    countDownText: string;
    unsubscribe$ = new Subject();
    isError: boolean;
    errMsg: string;

    isError2: boolean;
    errMsg2: string;

    constructor(
        private router: Router,
        private pcbindSrv: PcbindService,
        private store: Store,
        private appComponent: AppComponent,
        private applicationComponent: ApplicationComponent,
        private http: HttpClient
    ) { }

    async ngOnInit() {
        await this.applicationComponent.ready();
        await this.applicationComponent.setStep(1);

        this.validWayGroup.get('email').disable();
        this.validWayGroup.get('phone').disable();

        this.validWayGroup.get('way').valueChanges.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(v => {
            switch (v) {
                case 'email':
                    this.validWayGroup.get('email').enable();
                    this.validWayGroup.get('email').setValidators([Validators.email, Validators.required]);
                    this.validWayGroup.get('email').updateValueAndValidity();
                    this.validWayGroup.get('phone').disable();
                    this.validWayGroup.get('phone').setValue(null);
                    this.validWayGroup.get('phone').clearValidators();
                    this.validWayGroup.get('phone').updateValueAndValidity();
                    break;
                case 'phone':
                    this.validWayGroup.get('email').disable();
                    this.validWayGroup.get('email').clearValidators();
                    this.validWayGroup.get('email').updateValueAndValidity();
                    this.validWayGroup.get('email').setValue(null);
                    this.validWayGroup.get('phone').enable();
                    this.validWayGroup.get('phone').setValidators([Validators.pattern(/((?=(09))[0-9]{10})$/), Validators.required]);
                    this.validWayGroup.get('phone').updateValueAndValidity();
                    break;
                default:
                    break;
            }
        });

        this.validWayGroup.get('email').valueChanges.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((v: string) => {
            v = v || '';
            if (v.length > 2) {
                this.emailOptions = [];
                const last2Character = v.slice(v.length - 2, v.length);

                switch (last2Character) {
                    case '@g':
                        this.emailOptions.push(v.replace('@g', '@gmail.com'));
                        break;
                    case '@h':
                        this.emailOptions.push(v.replace('@h', '@hotmail.com'));
                        break;
                    case '@y':
                        this.emailOptions.push(v.replace('@y', '@yahoo.com.tw'));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /** 發送認證碼 */
    async sendCode() {
        this.isLoading = true;
        this.isError = false;
        if (this.validWayGroup.get('way').value === 'email') {
            const email = this.validWayGroup.get('email').value;
            // LDAP: 確認 Email 帳號是否存在
            try {
                const { success, EmailExists, errMsg } = await this.pcbindSrv.checkEmail(email) as any;
                if (success) {
                    if (EmailExists) {
                        // 請使用者用 LDAP 帳號登入
                        this.emailAlreadyExist = true;
                    } else {
                        this.emailAlreadyExist = false;
                        try {
                            const rsp = await this.pcbindSrv.sendEmailCode(email) as any;
                            this.checkSendedResult(rsp);
                        } catch (error) {
                            this.isError = true;
                            this.errMsg = "發送email驗證碼發生錯誤";
                            console.log("發送email驗證碼發生錯誤");
                            console.log(error);
                        }
                    }
                } else {
                    this.isError = true;
                    this.errMsg = errMsg;
                }
            } catch (error) {
                this.isError = true;
                this.errMsg = "檢查email資訊發生錯誤";
                console.log("檢查email資訊發生錯誤");
                console.log(error);
            }
        } else {
            const phone = this.validWayGroup.get('phone').value;
            try {
                const rsp = await this.pcbindSrv.sendSMSCode(phone) as any;
                this.checkSendedResult(rsp);
            } catch (error) {
                this.isError = true;
                this.errMsg = "發送手機驗證碼發生錯誤";
                console.log("發送手機驗證碼發生錯誤");
                console.log(error);
            }
        }
        this.isLoading = false;
    }

    checkSendedResult(rsp: any) {
        const { success, restTime, forbidden } = rsp;
        this.timerIsStart = forbidden;

        if (success) {
            this.validCodeSended = true;
        } else {
            // this.validCodeSended = false;
            // 1 hr 小時內已發送了兩次驗證碼，請直接輸入驗證碼！！
            if (this.validWayGroup.get('way').value === 'phone' && forbidden) {
                this.validCodeSended = true;
                this.validWayGroup.disable();
                const { minutes, seconds, milliseconds } = restTime;
                this.startTimer(minutes * 60 + seconds);
            }
        }
    }

    startTimer(time: number) {
        setInterval(() => {
            if (time > 0) {
                time -= 1;
                const minutes = Math.floor(time / 60);
                const seconds = time % 60;
                this.countDownText = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
            } else {
                this.timerIsStart = false;
                clearInterval();
            }
        }, 1000);
    }

    /** 驗證碼輸入中 */
    onCodeChanged(code: string) {
        this.invalidCode = code.length === 0;

        if (code.length === 6) {
            this.codeInputCompleted = true;
            this.code = code
        } else {
            this.codeInputCompleted = false;
        }
    }

    /** 驗證碼驗證失敗，重新輸入驗證碼 */
    resetCodeInput() {
        ;
        this.ngOtpInputRef.setValue(null);
    }

    /** 確認驗證碼 */
    async checkValidCode() {
        this.isError2 = false;
        try {
            const { result, id, idNumber, email, phone } =
                this.validWayGroup.get('way').value === 'email'
                    ? await this.pcbindSrv.checkEmailCode(this.validWayGroup.get('email').value, this.code, 1) as any
                    : await this.pcbindSrv.checkSMSCode(this.validWayGroup.get('phone').value, this.code, 1) as any
                ;
            this.invalidCode = !result;

            if (result) {
                // 更新資料&檢查有沒有小孩(申請紀錄/已建立的紀錄)
                try {
                    await this.appComponent.reloadUserInfo();

                } catch (error) {
                    this.isError2 = true;
                    this.errMsg2 = "主機連線發生錯誤";
                    console.log("主機連線發生錯誤");
                    console.log(error);
                }
                if (this.appComponent.applyList) {
                    // 切換下一頁
                    if (this.appComponent.applyList.length > 0) {
                        this.router.navigate(['/', 'board']);
                    }
                    else {
                        this.router.navigate(['/', 'apply', '2']);
                    }
                    this.store.dispatch(new SetMember({ idNumber, email, phone }));
                }
                else {
                    // 
                    this.isError2 = true;
                    this.errMsg2 = "查詢已申請子女清單發生錯誤";
                    console.log("查詢已申請子女清單發生錯誤");
                }
            } else {
                this.resetCodeInput();
            }
        }
        catch (error) {
            this.isError2 = true;
            this.errMsg2 = "查驗驗證碼發生錯誤";
            console.log("查驗驗證碼發生錯誤");
            console.log(error);
        }
    }

    login() {
        //@ts-ignore
        if (window.ReactNativeWebView)
            window.location.replace('https://tp-cooc-url-command.web.app/commands/tp_login.html');
        else
            window.location.replace("/auth/login?isFirst=1");
    }
}
