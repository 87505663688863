<!-- <div class="main">
    <div class="titletop d-flex">
        <div class="titletop1">親子綁定</div>
        <div class="titletop2" (click)="close()">
            <img src="../../assets/img/close.svg">
        </div>
    </div>
    <hr class="mt-0">
    <div class="step-content">
        <router-outlet></router-outlet>
    </div>
</div> -->
<div class="main">
    <router-outlet></router-outlet>
</div>