<!-- <div class="titletop d-flex"
     style="position: relative;">
    <div class="titletop1">親子綁定</div>
    <div (click)="close()"
         style="
            position: absolute;
            z-index: 1;
            right: 16px;
            top: 16px;
            color: #1db7bd;
            cursor: pointer;
        ">
        登出</div>
</div> -->
<div *ngIf="!isApp()"
     class="titletop d-flex">
    <div class="d-flex align-items-center w-100">
        <div class="d-flex align-items-center ml-4 mr-auto">
            <img class="cologo"
                 src="../assets/img/logos.png"
                 alt="酷課雲 Logo" />
            <div class="coheader align-self-center ml-2 ml-sm-3"
                 style="word-break: keep-all;">
                親子綁定
                <span [attr.title]="jsonUserInfo()"
                      class="p-1"></span>
            </div>
        </div>
        <div class="ml-auto mr-4">
            <button class="btn btn-outline-custom rounded-xl bt-sign"
                    (click)="close()">
                登出
            </button>
        </div>
    </div>
</div>
<hr class="mt-0 cohr">
<div class="step-content">
    <mat-horizontal-stepper color="primary"
                            linear
                            #stepper
                            (selectionChange)="stepperChanged($event)">
        <mat-step [stepControl]="step1Control"
                  editable="false">
            <div></div>
        </mat-step>
        <mat-step [stepControl]="step2Control"
                  editable="true">
            <div></div>
        </mat-step>
        <mat-step [stepControl]="step3Control"
                  editable="true">
            <div></div>
        </mat-step>
        <mat-step [stepControl]="step4Control"
                  editable="true">
            <div></div>
        </mat-step>

        <ng-template matStepperIcon="edit"
                     let-index="index">
            {{index + 1}}
        </ng-template>
        <ng-template matStepperIcon="done"
                     let-index="index">
            {{index + 1}}
        </ng-template>
    </mat-horizontal-stepper>
    <div class="mat-horizontal-content-container" style="padding:0 20px 24px;">
        <router-outlet></router-outlet>
    </div>
</div>