import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PcbindService } from '../../core/pcbind.service';
import { Store } from '@ngxs/store';
import { Reset } from 'src/app/state/member.action';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { CustomValidator } from '../../shared/idNumberVlidator';
import { AppComponent } from 'src/app/app.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApplicationComponent } from '../application.component';

@Component({
    selector: 'app-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit, OnDestroy {

    @Output() validChange = new EventEmitter<boolean>();
    isLoading: boolean;
    mode: 'binding' | 'finish' = null;
    errorMsg: string;
    confirmErrorMsg: string;
    schoolList: any[] = [];
    // 驗證學生資料
    studentValid: boolean;
    // 驗證錯誤次數
    validErrorTime: number = 0;

    studentGroup = new FormGroup({
        'relation': new FormControl(null, [Validators.required]),
        'legalRepresent': new FormControl(null),
        'guardian': new FormControl(null),
        'level': new FormControl(null, [Validators.required]),
        'idNumber': new FormControl(null, [Validators.required]),
        // 'idNumber': new FormControl(null, [
        //     Validators.required,
        //     Validators.pattern(/^[A-Z]{1}[A-D]{1}[0-9]{8}$|^[A-Z]{1}[8-9]{1}[0-9]{8}$|^[A-Z]{1}[12]{1}[0-9]{8}$/),
        //     CustomValidator.validLastCode
        // ])
    });

    studentValidGroup = new FormGroup({
        'selectedSchool': new FormControl(null, [Validators.required]),
        'validResult': new FormControl(null, [Validators.requiredTrue])
    });
    get selectedSchool() {
        return this.studentValidGroup.get('selectedSchool');
    }

    unsubscribe$ = new Subject();

    constructor(
        private pcbindSrv: PcbindService,
        private store: Store,
        private dialog: MatDialog,
        public appComponent: AppComponent,
        private applicationComponent: ApplicationComponent,
        private http: HttpClient,
        private router: Router
    ) { }

    async ngOnInit() {
        await this.appComponent.ready();
        await this.applicationComponent.ready();
        await this.applicationComponent.setStep(2);

        console.log(this.appComponent.tempList);
        if (this.appComponent.tempList.length) {
            this.mode = 'finish';
        }
        else {
            this.mode = 'binding';
        }

        this.studentGroup.get('legalRepresent').disable();
        this.studentGroup.get('guardian').disable();
        this.studentGroup.get('relation').valueChanges.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(relation => {
            switch (relation) {
                case '法定代理人':
                    this.studentGroup.get('legalRepresent').enable();
                    this.studentGroup.get('legalRepresent').setValidators([Validators.required]);
                    this.studentGroup.get('legalRepresent').updateValueAndValidity();
                    this.studentGroup.get('guardian').disable();
                    this.studentGroup.get('guardian').setValue(null);
                    this.studentGroup.get('guardian').clearValidators();
                    this.studentGroup.get('guardian').updateValueAndValidity();
                    break;
                case '監護人':
                    this.studentGroup.get('legalRepresent').disable();
                    this.studentGroup.get('legalRepresent').clearValidators();
                    this.studentGroup.get('legalRepresent').updateValueAndValidity();
                    this.studentGroup.get('legalRepresent').setValue(null);
                    this.studentGroup.get('guardian').enable();
                    this.studentGroup.get('guardian').setValidators([Validators.required]);
                    this.studentGroup.get('guardian').updateValueAndValidity();
                    break;
                default:
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /** 確認學生親子綁定上限為兩位家長，確認學生親子關係是否已申請或申請通過 */
    async checkPCbinding() {
        this.errorMsg = "";

        if (!CustomValidator.valudIDNumber(this.studentGroup.get('idNumber').value)) {
            this.errorMsg = '身分證號不符合標準規格';
            return;
        }

        this.isLoading = true;
        this.confirmErrorMsg = "";
        this.studentGroup.disable();
        this.studentValid = false;
        const idNumber = this.studentGroup.get('idNumber').value;



        // if (this.appComponent.applyList.find(child => child.StudentIDNumber === idNumber && child.Status == "Approve")) {
        if (this.appComponent.applyList.find(child => child.StudentIDNumber === idNumber && child.Status != "Reject")) {
            this.studentValid = false;
            this.errorMsg = '已有申請資料';
        } else if (this.appComponent.tempList.find(child => child.id_number === idNumber)) {
            this.studentValid = false;
            this.errorMsg = '不得重複提交';
        } else {
            // 確認學生親子綁定上限
            let checkChildApplyRsp: any;
            try {
                checkChildApplyRsp = await this.http.post(`/api/imdb/check_children_applys`, { id_number: idNumber }).toPromise();
            }
            catch (error) {
                this.errorMsg = "檢查子女綁定上限發生錯誤";
                this.isLoading = false;
                this.studentGroup.enable();
                return;
            }
            const { success, bindable, message, errMsg } = checkChildApplyRsp;
            if (success) {
                if (bindable) {
                    await this.getSchools(); // 取得學校驗證清單
                } else {
                    this.studentValid = false;
                    this.errorMsg = message;
                }
            } else {
                this.studentValid = false;
                this.errorMsg = errMsg;
            }
        }
        this.isLoading = false;
        this.studentGroup.enable();
    }

    async getSchools() {
        const idNumber = this.studentGroup.get('idNumber').value;
        const level = this.studentGroup.get('level').value;
        // let { success, schools } = await this.pcbindSrv.getSchools(idNumber, level) as any;
        let randomSchoolRsp: any;
        try {
            randomSchoolRsp = await this.http.get(`/api/imdb/get_schools?id_number=${idNumber}&level=${level}`).toPromise();
        }
        catch (error) {
            this.studentValid = false;
            this.errorMsg = "查詢所屬學校發生錯誤";
            return;
        }
        let { success, schools } = randomSchoolRsp;

        if (success) {
            this.studentValid = true;
            let numberList: number[] = [1, 2, 3, 4, 5];
            // 學校清單亂數排序
            this.schoolList = [].concat(schools || []).map(school => {
                school['sequence'] = this.getNumber(numberList);
                return school;
            }).sort((a: SchoolRec, b: SchoolRec) => {
                if (a.sequence > b.sequence) {
                    return 1;
                } else {
                    return -1;
                }
            });

            this.validErrorTime = 0;
            this.studentGroup.disable();
            this.studentGroup.updateValueAndValidity();
        } else {
            this.validErrorTime += 1;
            this.studentValid = false;

            switch (this.validErrorTime) {
                case 1:
                    this.errorMsg = '該學層查無此學生';
                    break;
                case 2:
                    this.errorMsg = '該學層查無此學生，基於安全性考量，連續輸入錯誤3次將直接退出本服務';
                    break;
                case 3:
                    this.store.dispatch(new Reset());
                    break;
                default:
                    this.store.dispatch(new Reset());
                    break;
            }
        }
    }

    /** 抽號碼牌 */
    getNumber(numberList: number[]): number {
        const index = Math.floor(Math.random() * numberList.length);
        const sequence = numberList[index];
        numberList.splice(index, 1);

        return sequence;
    }

    /** 驗證使用者選的小孩學校是否正確 */
    async confirm() {
        const level = this.studentGroup.get('level').value;
        const idNumber = this.studentGroup.get('idNumber').value;
        const legalRepresent = this.studentGroup.get('legalRepresent').value ? this.studentGroup.get('legalRepresent').value : '';
        const guardian = this.studentGroup.get('guardian').value ? this.studentGroup.get('guardian').value : '';
        const schoolCode = this.selectedSchool.value;

        this.errorMsg = "";
        this.confirmErrorMsg = "";

        // const { success, children } = await this.pcbindSrv.bindChildren({ level, schoolCode, idNumber, legalRepresent, guardian }) as any;
        let bindChildResp: any;
        try {
            bindChildResp = await this.http.post('/api/imdb/bind_children', { level, schoolCode, idNumber, legalRepresent, guardian }).toPromise();
        } catch (error) {
            this.confirmErrorMsg = "驗證所屬學校發生錯誤";
            return;
        }
        const { success, children } = bindChildResp;
        if (success) {
            this.studentValidGroup.get('validResult').setValue(true);
            this.mode = 'finish';
            this.appComponent.tempList.push(children);
        } else {
            this.studentValidGroup.get('validResult').setValue(false);
            this.validErrorTime += 1;
            if (this.validErrorTime === 3) {
                this.store.dispatch(new Reset());
            }
        }
    }

    async removeChild(childRec: any) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: { message: `確定刪除「${childRec.child_name}」此學生？` }
        });

        dialogRef.afterClosed().pipe(
            take(1)
        ).subscribe(async v => {
            if (v) {
                // const { success } = await this.pcbindSrv.removeChild(childRec.id) as any;
                let removeChildRsp: any;
                try {
                    removeChildRsp = await this.http.post('/service/remove_child', { id: childRec.id }).toPromise();
                }
                catch (error) {
                    alert("移除子女殺生錯誤");
                    return;
                }
                const { success } = removeChildRsp;
                if (success) {
                    this.appComponent.tempList = this.appComponent.tempList.filter(child => child.id !== childRec.id);
                }
            }
        });
    }

    newPCBind() {
        this.studentValid = false;
        this.studentGroup.enable()
        // this.studentGroup.get('relation').reset();
        // this.studentGroup.get('legalRepresent').reset();
        // this.studentGroup.get('guardian').reset();
        this.studentGroup.get('level').reset();
        this.studentGroup.get('idNumber').reset();
        this.studentValidGroup.get('selectedSchool').reset();
        this.studentValidGroup.get('validResult').reset();
        this.schoolList = [];
        this.mode = 'binding';
        this.studentValid = null;
        this.errorMsg = null;
    }

    nextStep() {
        console.log("step2 next");
        if (this.appComponent.userInfo.agreed)
            this.router.navigate(['/', 'apply', '4']);
        else
            this.router.navigate(['/', 'apply', '3']);

    }
}

interface SchoolRec {
    SchoolName: string;
    SchoolCode: string;
    SchoolType: string;
    District: string;
    sequence: number;
}

interface ChildRec {
    id: number;
    name: string;
    idNumber: string;
    className: string;
    relation: string;
}