import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-app-start',
    templateUrl: './app-start.component.html',
    styleUrls: ['./app-start.component.scss']
})
export class AppStartComponent implements OnInit {

    constructor(
        private router: Router,
        private appComponent: AppComponent,
        private http: HttpClient) { }

    async ngOnInit() {

        // 第一步被叫起來時才真的登出
        await this.http.get(`/service/logout`).toPromise();
        await this.appComponent.reloadUserInfo();
        await this.appComponent.reloadApplyList();
        this.router.navigate(['/', 'apply', '1']);
    }
}
