import { MemberModel } from '../state/member.state';

export class SetMember {
    static readonly type = '[member] set member';
    constructor(public member: MemberModel) {}
}

export class SetEmail {
    static readonly type = '[member] set email';
    constructor(public email: string) {}
}

export class SetPhone {
    static readonly type = '[member] set phone';
    constructor(public phone: string) {}
}

export class SetIDNumber {
    static readonly type = '[member] set idNumber';
    constructor(public idNumber: string) {}
}

export class Reset {
    static readonly type = '[member] reset';
    constructor() {}
}