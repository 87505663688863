<ng-container *ngIf="!showDoubleConfirm; else doubleConfirm">
    <h5 class="pstitle">請填寫家長資料</h5>
    <div class="form-group mt-3">
        <label for="ipIDNumber">家長身分證字號</label>
        <input type="text"
               class="form-control"
               id="ipIDNumber"
               [formControl]="idNumberControl"
               placeholder="身分證統一編號"
               autocomplete="off">
        <mat-error class="text-wrap"
                   *ngIf="somethingWentWrong">
            <p *ngFor="let msg of errMsgs">{{msg}}</p>
        </mat-error>
    </div>
    <div class="step-action">
        <button type="button"
                class="btn btn-custom btn-lg"
                [disabled]="idNumberControl.invalid"
                (click)="checkIDNumber()">
            驗證
        </button>
    </div>
    <ng-container [ngSwitch]="status">
        <ng-container *ngSwitchCase="'newLdap'">
            <ng-container [formGroup]="newLdapGroup">
                <div class="form-group">
                    <label for="ipName">家長姓名</label>
                    <input type="text"
                           class="form-control"
                           id="ipName"
                           placeholder="真實姓名"
                           formControlName="name"
                           autocomplete="off">
                </div>
                <div class="form-group">
                    <label for="ipEmail">電子郵件信箱</label>
                    <p class="lb-description">*此信箱將作為登入帳號</p>
                    <input type="text"
                           class="form-control"
                           id="ipEmail"
                           placeholder="信箱帳號"
                           formControlName="email"
                           autocomplete="off">
                </div>
                <div class="form-group">
                    <label for="ipPhone">行動電話/市話號碼</label>
                    <input type="text"
                           class="form-control"
                           id="ipPhone"
                           placeholder="請輸入您的手機或電話號碼"
                           formControlName="phone"
                           autocomplete="off"
                           maxlength="10">
                </div>
                <div class="step-action">
                    <button type="button"
                            class="btn btn-custom btn-lg"
                            [disabled]="newLdapGroup.invalid"
                            (click)=newLdapUserSendApply()>
                        下一步
                    </button>
                    <mat-error class="text-wrap"
                               *ngIf="!applySuccess">
                        <p *ngFor="let msg of errMsgs">{{msg}}</p>
                    </mat-error>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'ldap'">
            <ng-container [formGroup]="ldapGroup">
                <label class="form-check-label">您已申請臺北市校園單一身分驗證帳號</label>
                <div class="ml-0">
                    <input type="text"
                           class="form-control"
                           id="ipIDNumber"
                           formControlName="account"
                           placeholder="此帳號查無驗證"
                           autocomplete="off"
                           disabled>
                    <p style="color: #1DB7BD;">
                        本次申請資料經教師審核後，將綁定於原有帳號中。
                    </p>
                </div>
                <div class="form-group">
                    <label for="ipName">家長姓名</label>
                    <input type="text"
                           class="form-control"
                           id="ipName"
                           placeholder="真實姓名"
                           formControlName="name"
                           autocomplete="off"
                           [attr.disabled]="ldapAccount.name">
                </div>
                <div class="form-group">
                    <label for="ipEmail">電子郵件信箱</label>
                    <input type="text"
                           class="form-control"
                           id="ipEmail"
                           placeholder="信箱帳號"
                           formControlName="email"
                           autocomplete="off"
                           [attr.disabled]="ldapAccount.email">
                </div>
                <div class="form-group">
                    <label for="ipPhone">行動電話/市話號碼</label>
                    <input type="text"
                           class="form-control"
                           id="ipPhone"
                           placeholder="請輸入您的手機或電話號碼"
                           formControlName="phone"
                           autocomplete="off"
                           [attr.disabled]="ldapAccount.phone"
                           [attr.maxlength]="ldapAccount.phone?null:10">
                </div>
                <div class="notice">
                    <span class="material-icons"
                          style="color: #ff9b3f;">error_outline</span>
                    <div class="mr-3">提醒您，送出資料不等於完成綁定流程，請於下頁查看目前申請進度。</div>
                </div>
                <div class="step-action">
                    <button type="button"
                            class="btn btn-custom btn-lg"
                            (click)="oldLDAPUserSendApply()"
                            [disabled]="ldapGroup.invalid">
                        下一步
                    </button>
                    <mat-error class="text-wrap"
                               *ngIf="!applySuccess">
                        <p *ngFor="let msg of errMsgs">{{msg}}</p>
                    </mat-error>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'censoring'">
            <ng-container [formGroup]="censoringGroup">
                <p>您已有一筆申請紀錄待審核，根據單一帳號原則，申請紀錄將予以合併。基於安全性考量，請以原申請資料重新驗證。驗證後將為您送出申請。</p>
                <h5 class="pstitle">請選擇取得驗證碼方式</h5>
                <mat-radio-group class="d-flex flex-column"
                                 color="primary"
                                 [formControlName]="'way'">
                    <mat-radio-button [value]="'email'">
                        電子郵件信箱
                    </mat-radio-button>
                    <div class="form-group ml-4">
                        <input type="email"
                               class="form-control"
                               [formControlName]="'email'"
                               placeholder="信箱帳號"
                               autocomplete="off">
                    </div>
                    <mat-radio-button [value]="'phone'">
                        行動電話
                    </mat-radio-button>
                    <div class="form-group ml-4">
                        <input type="text"
                               class="form-control"
                               [formControlName]="'phone'"
                               placeholder="電話號碼"
                               autocomplete="off">
                    </div>
                </mat-radio-group>
                <div class="step-action">
                    <button type="button"
                            class="btn btn-custom btn-lg"
                            [disabled]="isCensorInfoValid || isLoading"
                            (click)=sendCode()>
                        {{isLoading ? '驗證碼發送中...' : '取得驗證碼'}}
                    </button>
                    <mat-error *ngIf="isError">{{errMsg}}</mat-error>
                </div>
                <!-- 驗整碼發送成功後顯示 -->
                <ng-container *ngIf="validCodeSended">
                    <h5 class="pstitle mt-5 mb-2">請輸入驗證碼</h5>
                    <div class="form-group mt-2">
                        <p class="lb-description">
                            驗證碼時效為1小時，逾時需重新取得驗證碼。
                        </p>
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <ng-otp-input #ngOtpInput
                                          (onInputChange)="onCodeChanged($event)"
                                          [config]="otpInputConfig">
                            </ng-otp-input>
                        </div>
                    </div>
                    <div class="step-action"
                         style="margin-top: 116px;">
                        <button type="button"
                                class="btn btn-custom btn-lg"
                                [disabled]="!codeInputCompleted"
                                (click)="checkValidCode()">
                            下一步
                        </button>
                        <mat-error class="mt-2"
                                   *ngIf="invalidCode">無效的驗證碼</mat-error>
                        <mat-error class="text-wrap"
                                   *ngIf="!applySuccess">
                            <p *ngFor="let msg of errMsgs">{{msg}}</p>
                        </mat-error>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #doubleConfirm>
    <h5 class="pstitle mt-2">請再次確認填寫資料是否正確</h5>
    <div class="pbox mt-2">
        <div class="font-weight-bold pb-2">學生資訊</div>
        <div *ngFor="let child of appComponent.tempList">
            <div><span class="pbox-titl">姓名：{{child.child_name}} {{child.legal_represent || child.guardian}}</span>
            </div>
        </div>
        <div class="font-weight-bold pb-2 pt-3">個人資訊</div>
        <div>
            <div><span class="pbox-titl">姓名：{{confirmName}}</span></div>
            <div><span class="pbox-titl">身分證字號：{{confirmIdNumber}}</span></div>
            <div><span class="pbox-titl">電話：{{confirmPhone}}</span></div>
            <div><span class="pbox-titl">電子信箱：{{confirmEMail}}</span></div>
            <p class="beautiful-color mb-0">信箱為家長帳號及審核通知位址，請務必填寫正確</p>
        </div>
    </div>
    <h5 class="pstitle mt-3">電子簽名</h5>
    <div class="pbox mt-2 p-3">
        <div class="pbox-titl mb-2">請務必簽署全名</div>
        <div #mycanvas
             style="border: 2px dashed #ddd; width: 100%; height: 150px;"></div>
        <div class="mt-3 text-right">
            <button type="button"
                    style="color: #ff9b3f; border:1px solid #ff9b3f"
                    class="btn m-0 px-2 py-1"
                    (click)="resetCanvas()">清除</button>
        </div>

        <!-- <div id="image" style="width: 100%; height: 150px;"></div> -->

    </div>
    <div class="notice mt-4">
        <span class="material-icons"
              style="color: #ff9b3f;">error_outline</span>
        <div class="mr-3">提醒您，送出資料不等於完成綁定流程，請於下頁查看目前申請進度。</div>
    </div>

    <div class="d-flex flex-row flex-wrap justify-content-between my-4">
        <button class="btn btn-custom-outline btn-md bt-sign"
                (click)="confirmCancel()">
            返回修改
        </button>
        <button class="btn btn-custom btn-md border-0 bt-sign"
                [disabled]="!signed"
                (click)="confirmOK()">
            送出資料
        </button>
        <mat-error class="text-wrap"
                   *ngIf="!applySuccess">
            <p *ngFor="let msg of errMsgs">{{msg}}</p>
        </mat-error>
    </div>
</ng-template>