<h5 class="pstitle">請詳閱以下個資使用聲明</h5>
<div class="agreement m-auto">
    <div>
        <p class="p1">
            <span>臺北市政府教育局（以下簡稱本局）提供臺北市親師生校園學習及生活之E化服務，如學雜費繳款、社團活動報名、到離校資訊、線上請假、調查表、班級通知事項及臺北酷課雲（親師生學習整合平臺）之其他相關服務，家長使用前述服務須先申請臺北市校園單一身分驗證親子帳號。為確保學生個資安全，該帳號僅提供法定代理人及監護人以實名制線上申請。請至臺北酷課雲</span>
            <span class="s1">
                （<span class="s2">
                    <a href="https://cooc.tp.edu.tw/"
                       rel="noopener"
                       target="_blank">https://cooc.tp.edu.tw/</a>
                </span>）選擇親子綁定服務，驗證個人電子郵件信箱或行動電話
            </span>
            <span>，並填寫親子關係綁定相關資料（含學生個人資料、家長之姓名、稱謂、身分證/居留證、電子信箱及電話號碼），填寫完成並簽名切結。由校方審核資料相符，以電子郵件方式通知予家長。家長即可於「臺北酷課雲（親師生學習整合平臺）」使用前述親子關係綁定填寫之信箱作為帳號，透過臺北市校園單一身分驗證方式登入，享有上述E化服務。本局為蒐集相關個人資料，請務必詳閱「臺北市校務行政系統親子關係綁定」相關資料，確認同意後再送交申請資料。</span>
        </p>
        <ol class="ol1">
            <li class="li2">告知個人資料事項：
                <p class="p3">
                    <span>依據個人資料保護法等相關規定，明列以下告知事項〔下列代號可於國家發展委員會主管法規查詢系統網站查詢「個人資料保護法之特定目的及個人資料之類別」相關代號說明（</span>
                    <span class="s2">
                        <a href="https://theme.ndc.gov.tw/lawout/LawContent.aspx?id=GL000316"
                           rel="noopener"
                           target="_blank">
                            https://theme.ndc.gov.tw/lawout/LawContent.aspx?id=GL000316
                        </a>
                    </span>
                    <span>）〕：</span>
                </p>
                <ol class="ol1">
                    <li class="li2">蒐集個人資料之機關及公司：本局、所屬學校及本局業務委外廠商。</li>
                    <li class="li2">蒐集之目的：</li>
                    <ol class="ol1">
                        <li class="li2">
                            代號：072政令宣導、109教育或訓練行政、116場所進出安全管理、135資（通）訊服務、136資（通）訊與資料庫管理、137資通安全與管理、157調查、統計與研究分析、158學生（員）（含畢、結業生）資料管理、159學術研究、175其他地方政府機關暨所屬機關構內部單位管理、公共事務監督、行政協助及相關業務。
                        </li>
                        <li class="li2">為提供臺北酷課雲（親師生學習整合平臺）相關服務，如學雜費繳款、社團活動報名、到離校資訊、線上請假、公佈欄、調查表及班級通知事項等服務。</li>
                    </ol>
                    <li class="li2">個人資料之類別：</li>
                    <ol class="ol1">
                        <li class="li2">識別類代號：C001辨識個人者、C003政府資料中之辨識者。</li>
                        <li class="li2">特徵類代號：C011個人描述。</li>
                    </ol>
                    <li class="li2">個人資料利用之期間、對象、地區及方式：</li>
                    <ol class="ol1">
                        <li class="li2">期間：個人資料蒐集之特定目的在本市各學層就讀期間或本局依相關法令或契約約定執行業務所必須之保存期間。</li>
                        <li class="li2">對象：本局、臺北市政府資訊局、學校、悠遊卡股份有限公司及本局業務委外廠商。</li>
                        <li class="li2">地區：所有蒐集之個人資料將於中華民國領域範圍內使用。</li>
                        <li class="li2">方式：用於臺北酷課雲（親師生學習整合平臺）各式服務、悠遊付親子綁定服務。</li>
                    </ol>
                    <li class="li2">
                        依個人資料保護法第三條規定得行使之權利及方式：申請者於身分獲確認後，得向貴子女就讀學層之校務行政系統提出申請，以查詢、閱覽、製給複製本、補充／更正、請求停止蒐集、處理、利用或刪除個人資料內容。
                    </li>
                    <li class="li2">
                        不提供個人資料之權益影響：可以自由選擇是否提供個人資料，若拒絕提供申辦所需之全部或部分個人資料，或嗣後要求刪除、停止利用個人資料內容，本局將無法提供特定之服務，尚祈見諒。</li>
                </ol>
            </li>
        </ol>
        <!-- <ol class="ol1">
            <ol class="ol1">
                <li class="li2">2、 勾選不同意或未繳回此同意書者，將無法享有臺北酷課雲（親師生學習整合平臺）各式服務、悠遊付親子綁定服務。</li>
                <li class="li2">3、 若勾選不同意或未繳回此同意書者，爾後又希望享有臺北酷課雲（親師生學習整合平臺）各式服務、悠遊付親子綁定服務，須重新填寫本同意書並繳回學生就讀之學校。</li>
            </ol>
        </ol> -->
    </div>
</div>
<mat-checkbox color="primary"
              class=" mt-2"
              [formControl]="agreementControl">
    本人已詳閱並同意以上條款聲明
</mat-checkbox>
<div class="step-action mt-3">
    <div class="d-flex flex-row flex-wrap justify-content-between">
        <button class="btn btn-custom-outline btn-md bt-sign"
                (click)="disagree()">
            不同意並退出
        </button>
        <button class="btn btn-custom btn-md border-0 bt-sign"
                [disabled]="agreementControl.invalid"
                (click)="confirm()">
            下一步
        </button>
    </div>
</div>