import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { take, takeUntil } from 'rxjs/operators';
import { PcbindService } from '../core/pcbind.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { Step4Component } from './step4/step4.component';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('stepper') stepper: MatStepper;
    // @ViewChild(Step4Component) step4: Step4Component;

    agreed: boolean;
    step1Control = new FormControl(null, [Validators.requiredTrue]);
    step2Control = new FormControl(null, [Validators.requiredTrue]);
    step3Control = new FormControl(null, [Validators.requiredTrue]);
    step4Control = new FormControl(null, [Validators.requiredTrue]);

    maxTravel = 0;

    constructor(
        private router: Router,
        private pcbindSrv: PcbindService,
        private route: ActivatedRoute,
        private detector: ChangeDetectorRef,
        private dialog: MatDialog,
        private appComponent: AppComponent
    ) { }

    async ngOnInit() {
        await this.appComponent.ready();
    }

    jsonUserInfo() {
        return JSON.stringify(this.appComponent.userInfo);
    }

    private readyCallBack: any[] = [];
    ready() {
        return new Promise<void>(resolve => {
            if (this.stepper) resolve();
            else {
                this.readyCallBack.push(resolve);
            }
        });
    }

    async ngAfterViewInit() {
        // 載入完成
        this.readyCallBack.forEach(resolve => { resolve(); });
        this.readyCallBack = [];


        // this.stepper.selectedIndex = 0;
        // this.route.paramMap.pipe(
        //     takeUntil(this.unsubscribe$)
        // ).subscribe(async (param: ParamMap) => {
        //     const step = param.get('step');
        //     console.log(step);
        //     if (step != "1") {
        //         await this.appComponent.ready();
        //         if (!this.appComponent.userInfo.id)
        //             this.router.navigate(['/', 'start']);
        //     }
        //     switch (step) {
        //         case '1':
        //             break;
        //         case '2':
        //             this.step1Control.setValue(true);
        //             console.log("this.stepper.selectedIndex = 1;");
        //             this.stepper.selectedIndex = 1;
        //             break;
        //         case '3':
        //             this.step1Control.setValue(true);
        //             this.step2Control.setValue(true);
        //             console.log("this.stepper.selectedIndex = 2;");
        //             this.stepper.selectedIndex = 2;
        //             break;
        //         case '4':
        //             this.step1Control.setValue(true);
        //             this.step2Control.setValue(true);
        //             this.step3Control.setValue(true);
        //             console.log("this.stepper.selectedIndex = 3;");
        //             this.stepper.selectedIndex = 3;
        //             break;
        //         default:
        //             break;
        //     }
        //     this.detector.detectChanges();
        // });
    }

    ngOnDestroy(): void {
        // this.unsubscribe$.next();
        // this.unsubscribe$.complete();
    }

    async setStep(step) {
        if (step == 1) {
            this.maxTravel = 0;
            this.step1Control.reset();
            this.step2Control.reset();
            this.step3Control.reset();
            this.step4Control.reset();
            this.stepper.reset();
        } else {
            await this.appComponent.ready();
            if (!this.appComponent.userInfo.id) {
                this.router.navigate(['/', 'start']);
                return;
            }
        }
        if (this.maxTravel < step - 1) {
            for (let i = 0; i < step; i++) {
                [this.step1Control, this.step2Control, this.step3Control, this.step4Control][i].setValue(true);
            }
            for (let i = this.stepper.selectedIndex; i < step; i++) {
                this.stepper.next();
            }
        }
        this.stepper.selectedIndex = step - 1;
    }

    stepperChanged(e: any) {
        const { previouslySelectedIndex, selectedIndex } = e;
        this.router.navigate(['/', 'apply', selectedIndex + 1]);

        if (this.maxTravel < selectedIndex)
            this.maxTravel = selectedIndex;

        // if (previouslySelectedIndex === 3) {
        //     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        //         data: {
        //             message: '尚未完成綁定，現在離開將會遺失已填寫資料。是否離開？'
        //         }
        //     });

        //     dialogRef.afterClosed().pipe(
        //         take(1)
        //     ).subscribe(v => {
        //         // if (v) {
        //         //     this.step4.reset();
        //         // } else {
        //         //     this.step2Valid(true);
        //         //     this.step3Valid(true);
        //         // }
        //     });
        // }
    }

    // step1Valid(e: boolean) {
    //     this.step1Control.setValue(e);
    //     this.stepper.next();
    // }

    // step2Valid(e: any) {
    //     this.step2Control.setValue(e);
    //     this.stepper.next();
    // }

    // step3Valid(e: any) {
    //     this.step3Control.setValue(e);
    //     this.stepper.next();
    // }

    // step4Valid(e: any) {
    //     this.step4Control.setValue(e);
    //     this.stepper.next();
    // }

    close() {
        if (this.stepper.selectedIndex == 0) {
            //@ts-ignore
            if (this.isApp())
                window.location.replace('https://cooc.tp.edu.tw/app/close');
            else
                window.location.replace('/service/logout?next=' + encodeURIComponent("https://cooc.tp.edu.tw/"));
        }
        else {
            // 申請進度查詢頁不需提示
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    message: '尚未完成綁定，現在離開將會遺失已填寫資料。是否離開？'
                }
            });

            dialogRef.afterClosed().pipe(
                take(1)
            ).subscribe(v => {
                if (v) {
                    if (this.isApp())
                        window.location.replace('https://cooc.tp.edu.tw/app/close');
                    else
                        window.location.replace('/service/logout?next=' + encodeURIComponent("https://cooc.tp.edu.tw/"));
                }
            });
        }
    }

    isApp() {
        //@ts-ignore
        return !!window.ReactNativeWebView;
    }
}
