import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { PcbindService } from '../core/pcbind.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { take } from 'rxjs/operators';
import { MessageDialogComponent } from '../shared/message-dialog/message-dialog.component';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-board',
    templateUrl: './board.component.html',
    styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {

    isLoading: boolean = false;
    childList: ChildRec[] = [];
    isError: boolean = false;
    errMsg: string;
    showMore: boolean = false;

    constructor(
        private pcbindSrv: PcbindService,
        private http: HttpClient,
        private appComponent: AppComponent,
        private router: Router,
        private dialog: MatDialog
    ) { }

    async ngOnInit() {
        await this.appComponent.ready();

        if (!this.appComponent.userInfo.idNumber)
            this.router.navigate(['/', 'apply', '1']);
        await this.getApplications();
    }


    async reload() {
        this.childList = [];
        await this.getApplications();
    }

    async restartAll() {
        if (this.childList.find(child => child.status == "認證")) {
            this.dialog.open(MessageDialogComponent, { data: { title: '帳號已經建立', message: '已有申請通過紀錄，無法進行註銷，請洽客服為您服務。' } });
        } else if (this.childList.find(child => child.status == "認證不通過")) {
            this.dialog.open(MessageDialogComponent, { data: { title: '認證不通過', message: '已有認證不通過紀錄，無法進行註銷，如需重新申請請點新增綁定學生，填寫後重新送出。' } });
        }
        else {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    message: '將會刪除所有的申請紀錄，並重新開始，是否刪除？'
                }
            });

            dialogRef.afterClosed().pipe(
                take(1)
            ).subscribe(async (confirm) => {
                if (confirm) {
                    try {
                        await this.http.get(`/api/imdb/deleteAll`).toPromise();
                    }
                    catch (error) {
                        this.dialog.open(MessageDialogComponent, { data: { title: '刪除資料發生錯誤', message: '刪除資料發生錯誤。' } });
                        await this.getApplications();
                        return;
                    }
                    this.router.navigate(['/', 'start']);
                }
            });
        }
    }

    /** 取得親子綁定申請紀錄 */
    async getApplications() {
        this.isLoading = true;
        const { success, data, errMsg } = await this.pcbindSrv.getRelationships() as any;

        if (success) {
            console.log(data);
            // ClassId: 264223
            // ParentEmail: "grady.liu@ischool.com.tw"
            // ParentIDNumber: "A104283382"
            // ParentName: "格雷迪"
            // Relationship: "父親"
            // SchoolCode: "000002"
            // SchoolType: "高職"
            // Status: "Apply"
            // StudentIDNumber: "YB94100553"
            // StudentName: "吳小美"
            // UpdateTime: "20210517162446"
            // const { DATA_LIST, DATA_ROW, StatusCode } = data as any;
            this.childList = [].concat(data || []).map(data => {
                // Apply(申請)、Approve (認證)、 Reject (認證不通過) 、Remove(註銷) v1.4
                switch (data.Status) {
                    case 'Apply':
                        data.Status = '申請';
                        break;
                    case 'Approve':
                        data.Status = '認證';
                        break;
                    case 'Reject':
                        data.Status = '認證不通過';
                        break;
                    case 'Remove':
                        data.Status = '註銷';
                        break;
                    default:
                        break;
                }

                return {
                    name: data.StudentName,
                    id_number: data.StudentIDNumber,
                    status: data.Status
                };
            });
        } else {
            this.isError = true;
            this.errMsg = errMsg;
        }
        this.isLoading = false;
    }

    async deleteOne(child_id_number) {
        if (this.childList.length == 1) {
            await this.restartAll();
            return;
        }

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: '是否確認刪除？'
            }
        });

        dialogRef.afterClosed().pipe(
            take(1)
        ).subscribe(async (confirm) => {
            if (confirm) {
                try {
                    await this.http.get(`/api/imdb/deleteOne?child_id_number=${child_id_number}`).toPromise();
                }
                catch (error) {
                    this.dialog.open(MessageDialogComponent, { data: { title: '刪除資料發生錯誤', message: '刪除資料發生錯誤。' } });
                }
                await this.getApplications();
                await this.appComponent.reloadApplyList();
            }
        });
    }

    hasApply() {
        return this.childList.filter((item: any) => item.status == "申請").length > 0;
    }

    close() {
        if (this.isApp())
            window.location.replace('https://cooc.tp.edu.tw/app/close');
        else
            window.location.replace('/service/logout?next=' + encodeURIComponent("https://cooc.tp.edu.tw/"));
    }

    isApp() {
        //@ts-ignore
        return !!window.ReactNativeWebView;
    }
}

interface ChildRec {
    name: string;
    status: string;
}