import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PcbindService } from '../../core/pcbind.service';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { MemberModel } from 'src/app/state/member.state';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SetIDNumber } from '../../state/member.action';
import { CustomValidator } from '../../shared/idNumberVlidator';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from 'src/app/app.component';
import { ApplicationComponent } from '../application.component';
declare let $: any;

@Component({
    selector: 'app-step4',
    templateUrl: './step4.component.html',
    styleUrls: ['./step4.component.scss']
})
export class Step4Component implements OnInit, OnDestroy {



    @Output() validChange = new EventEmitter<boolean>();
    @Select(state => state.member) member$: Observable<MemberModel>;

    // @ViewChild('mycanvas') mycanvas: ElementRef<HTMLDivElement>;
    @ViewChild('mycanvas', { static: false }) mycanvas?: ElementRef<HTMLDivElement>;

    isLoading: boolean;

    isError: boolean;
    errMsg: string;

    signed: boolean = false;

    member: MemberModel = {} as MemberModel;
    ldapAccount: any = {};
    validCodeSended: boolean;
    timerIsStart: boolean;
    countDownText: string;
    idNumberControl = new FormControl(null, [
        Validators.required
    ]);
    // idNumberControl = new FormControl(null, [
    //     Validators.required,
    //     Validators.pattern(/^[A-Z]{1}[A-D]{1}[0-9]{8}$|^[A-Z]{1}[8-9]{1}[0-9]{8}$|^[A-Z]{1}[12]{1}[0-9]{8}$/),
    //     CustomValidator.validLastCode
    // ]);
    status: 'newLdap' | 'ldap' | 'censoring' | '' = '';

    showDoubleConfirm = false;

    somethingWentWrong: boolean = false;
    applySuccess: boolean = false;
    errMsgs: string[] = [];
    code: string; // 驗證碼
    codeInputCompleted: boolean; // 驗證碼輸入完畢 
    invalidCode: boolean; // 驗證碼驗證失敗
    otpInputConfig = {
        length: 6,
        allowNumbersOnly: true,
        inputStyles: {
            'width': '14%',
            'height': '60px',
            'font-size': '1rem'
        }
    };

    // 尚未申請 LDAP 帳號
    newLdapGroup = new FormGroup({
        'name': new FormControl(null, [Validators.required]),
        'email': new FormControl(null, [Validators.email, Validators.required]),
        'phone': new FormControl(null, [Validators.pattern(/[0-9]{10}$/), Validators.required])
    });

    // 已有 LDAP 帳號 
    ldapGroup = new FormGroup({
        'account': new FormControl(null),
        'name': new FormControl(null, [Validators.required]),
        'email': new FormControl(null, [Validators.email, Validators.required]),
        'phone': new FormControl(null, [Validators.pattern(/[0-9]{10}$/), Validators.required])
    });

    // 已有申請紀錄待審核中
    censoringGroup = new FormGroup({
        'way': new FormControl(null, [Validators.required]),
        'name': new FormControl(null),
        'email': new FormControl(null, [Validators.required]),
        'phone': new FormControl(null, [Validators.pattern(/((?=(09))[0-9]{10})$/), Validators.required]),
        'code': new FormControl(null, [Validators.required])
    });

    get isCensorInfoValid() {
        if (this.censoringGroup.get('way').value === 'phone') {
            return this.censoringGroup.get('phone').valid;
        }

        if (this.censoringGroup.get('way').value === 'email') {
            return this.censoringGroup.get('email').valid;
        }

        return false;
    }

    unsubscribe$ = new Subject();

    //畫布相關
    show = false;
    needSetJSignature = false;
    imageURL = '';

    constructor(
        private router: Router,
        private pcbindSrv: PcbindService,
        private http: HttpClient,
        private store: Store,
        public appComponent: AppComponent,
        private applicationComponent: ApplicationComponent,
    ) { }

    async ngOnInit() {
        await this.applicationComponent.ready();
        await this.appComponent.ready();
        await this.applicationComponent.setStep(4);

        // this.member$.pipe(
        //     takeUntil(this.unsubscribe$)
        // ).subscribe(v => {
        //     this.member = v;
        // });

        this.censoringGroup.get('email').disable();
        this.censoringGroup.get('phone').disable();

        // 如果目前的使用者已經有識別的身分證號，直接使用不給修改
        if (this.appComponent.userInfo.idNumber) {
            this.idNumberControl.setValue(this.appComponent.userInfo.idNumber);
            this.idNumberControl.disable();
            await this.checkIDNumber();
        }
    }

    //canvas畫布
    ngAfterViewChecked() {

        // 判斷畫面有切換時再執行
        if (this.needSetJSignature && this.show) {
            // 這樣就不會一直反覆處理

            this.signed = false;
            // @ts-ignore
            $(this.mycanvas.nativeElement).jSignature({ width: '100%', height: 150, 'decor-color': 'transparent', lineWidth: '2', });
            $(this.mycanvas.nativeElement).bind('change', () => { this.signed = true; });
            // canvas 已加上 jSignature，務必將 needSetJSignature 設為 false，避免多次加上
            this.needSetJSignature = false;
        }
    }

    canvasShow() {
        this.show = true;
        // canvas 被重繪，需加上 jSignature
        if (this.show) this.needSetJSignature = true;
    }



    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    reset() {
        this.status = '';

        this.censoringGroup.get('email').disable();
        this.censoringGroup.get('phone').disable();
        this.censoringGroup.get('way').setValue(null);
        this.censoringGroup.get('name').setValue(null);
        this.censoringGroup.get('email').setValue(null);
        this.censoringGroup.get('phone').setValue(null);
        this.censoringGroup.get('code').setValue(null);

        this.ldapGroup.get('account').setValue(null);
        this.ldapGroup.get('name').setValue(null);
        this.ldapGroup.get('email').setValue(null);
        this.ldapGroup.get('phone').setValue(null);

        this.newLdapGroup.get('name').setValue(null);
        this.newLdapGroup.get('email').setValue(null);
        this.newLdapGroup.get('phone').setValue(null);
    }

    async checkIDNumber() {
        this.reset();
        this.somethingWentWrong = false;
        this.errMsgs = [];


        if (!CustomValidator.valudIDNumber(this.idNumberControl.value)) {
            this.somethingWentWrong = true;
            this.errMsgs = ['身分證號不符合標準規格'];
            return;
        }


        // const { accountExist, isTeacher, isParent, isStudent } = await this.pcbindSrv.checkIDNumber(this.idNumberControl.value) as any;
        let checkIDNumberResp: any;
        try {
            checkIDNumberResp = await this.http.get(`/api/ldap/check_id_number?id_number=${this.idNumberControl.value}`).toPromise();
        }
        catch (error) {
            this.somethingWentWrong = true;
            this.errMsgs = ["檢查家長身分證發生錯誤"];
            return;
        }
        const { accountExist, isTeacher, isParent, isStudent, detail } = checkIDNumberResp;

        if (accountExist && isStudent) {
            this.somethingWentWrong = true;
            this.errMsgs = ["需輸入家長本人的身分證號"];
            return;
        }
        else if (accountExist && !isStudent) {
            // 除了確認身分證號對應的帳號已存在外，還要排除學生身份
            // if (accountExist && (isTeacher || isParent)) {
            this.status = 'ldap';
            this.ldapAccount = detail;
            this.ldapGroup.get('account').setValue(this.ldapAccount.account);
            this.ldapGroup.get('email').setValue(this.ldapAccount.email);
            this.ldapGroup.get('phone').setValue(this.ldapAccount.phone);
            this.ldapGroup.get('name').setValue(this.ldapAccount.name);
        } else {
            // imdb GetBatchRelationship
            // const { success, result } = await this.pcbindSrv.checkParentApplys(this.idNumberControl.value) as any;
            let checkParentApplyResp: any;
            try {
                checkParentApplyResp = await this.http.post(`/api/imdb/check_parent_applys`, { parentIDNumber: this.idNumberControl.value }).toPromise();
            }
            catch (error) {
                this.somethingWentWrong = true;
                this.errMsgs = ["檢查已綁定子女發生錯誤"];
                return;
            }
            const { success, result } = checkParentApplyResp;

            if (success) { // 成功取得資料
                const { StatusCode, DATA_ROW, DATA_LIST } = result;
                const dataList = [].concat(DATA_LIST || []);

                if (dataList.filter(data => data.Status === 'Apply').length > 0) {  // 有審核中資料
                    this.status = 'censoring';
                    // const { ParentName, ParentEmail, ParentPhone} = dataList[0];
                    // 取得上次申請資料
                    // const { name, email, phone } = await this.pcbindSrv.getLatestInfoByIDNumber(this.idNumberControl.value) as any;

                    let getLatestInfoByIDNumberResp: any;
                    try {
                        getLatestInfoByIDNumberResp = await this.http.get(`/service/get_latest_info_by_idnumber?idNumber=${this.idNumberControl.value}`).toPromise();
                    }
                    catch (error) {
                        this.somethingWentWrong = true;
                        this.errMsgs = ["檢查合併資訊發生錯誤"];
                        return;
                    }
                    const { name, email, phone } = getLatestInfoByIDNumberResp;

                    this.censoringGroup.get('email').setValue(email);
                    this.censoringGroup.get('phone').setValue(phone);
                    this.censoringGroup.get('name').setValue(name);
                    this.censoringGroup.updateValueAndValidity();
                } else { // 無審核中資料，帶入步驟一資料
                    this.status = 'newLdap';
                    if (this.appComponent.userInfo.email) {
                        this.newLdapGroup.get('email').setValue(this.appComponent.userInfo.email);
                        this.newLdapGroup.get('email').disable();
                        this.newLdapGroup.get('email').updateValueAndValidity();
                    }
                    if (this.appComponent.userInfo.phone) {
                        this.newLdapGroup.get('phone').setValue(this.appComponent.userInfo.phone);
                        this.newLdapGroup.get('phone').disable();
                        this.newLdapGroup.get('phone').updateValueAndValidity();
                    }
                }
            } else {
                this.somethingWentWrong = true;
                this.errMsgs = [].concat(JSON.stringify(result));
            }
        }
    }

    async newLdapUserSendApply() {
        this.applySuccess = true;
        let ldapExist: any, localExist: any;
        try {
            [ldapExist, localExist] = (await Promise.all([
                this.http.get(`/api/ldap/check_email?email=${this.newLdapGroup.get('email').value}`).toPromise()
                , this.http.get(`/service/check_email_exist?email=${this.newLdapGroup.get('email').value}&id_number=${this.idNumberControl.value}`).toPromise()
            ]))
        } catch (error) {
            this.applySuccess = false;
            this.errMsgs = ["檢查email是否存在發生錯誤"];
            return;
        }
        if (ldapExist.EmailExists) {
            this.applySuccess = false;
            this.errMsgs = ["此email已有帳號，不得再次申請"];
            return;
        }
        if (localExist.exist) {
            this.applySuccess = false;
            this.errMsgs = ["此email已有申請紀錄，不得再次申請"];
            return;
        }

        await this.sendApplication(
            this.newLdapGroup.get('name').value,
            this.idNumberControl.value,
            this.newLdapGroup.get('email').value,
            this.newLdapGroup.get('phone').value
        );
    }

    /** 已有 LDAP帳號的家長送出申請資料 */
    async oldLDAPUserSendApply() {
        await this.sendApplication(
            this.ldapAccount.name || this.ldapGroup.get('name').value,
            this.idNumberControl.value,
            this.ldapAccount.email || this.ldapGroup.get('email').value,
            this.ldapAccount.phone || this.ldapGroup.get('phone').value
        );
    }

    /** 發送認證碼 */
    async sendCode() {
        this.isLoading = true;
        this.isError = false;
        try {
            if (this.censoringGroup.get('way').value === 'email') {
                const email = this.censoringGroup.get('email').value;
                const rsp = await this.pcbindSrv.sendEmailCode(email) as any;
                this.checkSendedResult(rsp);
            } else {
                const phone = this.censoringGroup.get('phone').value;
                const rsp = await this.pcbindSrv.sendSMSCode(phone, 4) as any;
                this.checkSendedResult(rsp);
            }
        }
        catch (error) {
            this.isError = true;
            this.errMsg = "發送驗證碼發生錯誤";
            console.log("發送驗證碼發生錯誤");
            console.log(error);
        }
        this.isLoading = false;
    }

    checkSendedResult(rsp: any) {
        const { success, restTime, forbidden } = rsp;
        this.timerIsStart = forbidden;

        if (success) {
            this.validCodeSended = true;
            this.idNumberControl.disable();
        } else {
            this.validCodeSended = false;

            if (this.censoringGroup.get('way').value === 'phone' && forbidden) {
                this.censoringGroup.disable();
                const { minutes, seconds, milliseconds } = restTime;
                this.startTimer(minutes * 60 + seconds);
            }
        }
    }

    startTimer(time: number) {
        setInterval(() => {
            if (time > 0) {
                time -= 1;
                const minutes = Math.floor(time / 60);
                const seconds = time % 60;
                this.countDownText = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
            } else {
                this.timerIsStart = false;
                clearInterval();
            }
        }, 1000);
    }

    /** 驗證碼輸入中 */
    onCodeChanged(code: string) {
        this.invalidCode = code.length === 0;

        if (code.length === 6) {
            this.codeInputCompleted = true;
            this.code = code
        } else {
            this.codeInputCompleted = false;
        }
    }

    /** 確認驗證碼 */
    async checkValidCode() {
        const name = this.censoringGroup.get('name').value;
        const idNumber = this.idNumberControl.value;
        const email = this.censoringGroup.get('email').value;
        const phone = this.censoringGroup.get('phone').value;

        try {
            if (this.censoringGroup.get('way').value === 'email') {
                const email = this.censoringGroup.get('email').value;
                const { result, id } = await this.pcbindSrv.checkEmailCode(email, this.code, 4) as any;
                this.invalidCode = !result;

                if (result) {
                    if (id != this.appComponent.userInfo.id) {
                        await this.appComponent.reloadUserInfo();
                        this.appComponent.tempList.forEach(item => { item.ref_member_id = id; });
                    }
                    await this.sendApplication(name, idNumber, email, phone);
                }
            } else {
                const phone = this.censoringGroup.get('phone').value;
                const { result, id } = await this.pcbindSrv.checkSMSCode(phone, this.code, 4) as any;

                if (result) {
                    if (id != this.appComponent.userInfo.id) {
                        await this.appComponent.reloadUserInfo();
                        this.appComponent.tempList.forEach(item => { item.ref_member_id = id; });
                    }
                    await this.sendApplication(name, idNumber, email, phone);
                }
            }
        }
        catch (error) {
            this.applySuccess = false;
            this.errMsgs = ["查驗驗證碼發生錯誤"];
        }
    }


    confirmName: string;
    confirmIdNumber: string;
    confirmEMail: string;
    confirmPhone: string;

    /** 送出申請資料 */
    async sendApplication(name: string, idNumber: string, email: string, phone: string) {
        this.confirmName = name;
        this.confirmIdNumber = idNumber;
        this.confirmEMail = email;
        this.confirmPhone = phone;
        this.showDoubleConfirm = true;

        this.canvasShow();

    }

    next() {
        this.store.dispatch(new SetIDNumber(this.idNumberControl.value));
        this.router.navigate(['/', 'board']);
    }

    async confirmCancel() {
        console.log("confirmCancel");
        this.showDoubleConfirm = false;
    }

    async confirmOK() {
        let name = this.confirmName;
        let idNumber = this.confirmIdNumber;
        let email = this.confirmEMail;
        let phone = this.confirmPhone;
        let signatureImage = $(this.mycanvas.nativeElement).jSignature("getData", "default");
        // let signatureImage = this.mycanvas.nativeElement.querySelector("canvas").toDataURL("image/jpeg");
        console.log("signatureImage", signatureImage);
        let imageStream = [];
        const BASE64_MARKER = ';base64,';
        const base64Index = signatureImage.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        const base64 = signatureImage.substring(base64Index);
        const binary_string = window.atob(base64);
        // const binary_string = Buffer.from(base64, 'base64').toString('utf-8');
        const len = binary_string.length;
        for (let i = 0; i < len; i++) {
            let byte = binary_string.charCodeAt(i);
            if (byte > 128)
                byte = -256 + byte;
            imageStream.push(byte);
        }


        this.errMsgs = [];
        // const { success, errorMsgs, result } = await this.pcbindSrv.sendApplication(name, idNumber, email, phone) as any;
        let sendApplication: any;
        try {
            console.log(this.appComponent.tempList);
            sendApplication = await this.http.post('/api/imdb/send_application', { name, idNumber, email, phone, imageStream, applyList: this.appComponent.tempList }).toPromise();
        }
        catch (error) {
            this.applySuccess = false;
            this.errMsgs.push("送出申請資料發生錯誤");
            return;
        }
        const { success, errorMsgs, result } = sendApplication;

        if (success) {
            await this.appComponent.reloadUserInfo();
            await this.appComponent.reloadApplyList();
            // 導向總攬頁
            this.store.dispatch(new SetIDNumber(this.idNumberControl.value));
            this.router.navigate(['/', 'board']);
        } else {
            this.applySuccess = success;
            this.errMsgs = [].concat(errorMsgs || []);
            if (this.errMsgs.length == 0)
                this.errMsgs.push("發送失敗，原因不明");
        }
    }



    async resetCanvas() {
        $(this.mycanvas.nativeElement).jSignature("reset");
        this.signed = false;
        // $("#image").html("");
    }

}
