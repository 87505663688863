<ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'binding'">
        <ng-container [formGroup]="studentGroup">
            <h5 class="pstitle" style="margin-bottom: 8px;">選擇與綁定學生的關係</h5>
            <p class="lb-description">綁定多位學生，請先擇一位填寫。</p>
            <mat-radio-group class="d-flex flex-column" color="primary" [formControlName]="'relation'">
                <mat-radio-button [value]="'法定代理人'">
                    法定代理人
                </mat-radio-button>
                <mat-form-field class="ml-2" appearance="outline">
                    <mat-label>選擇法定代理人，如：父親</mat-label>
                    <mat-select [formControlName]="'legalRepresent'">
                        <mat-option [value]="'父親'">父親</mat-option>
                        <mat-option [value]="'母親'">母親</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-radio-button [value]="'監護人'">
                    監護人
                </mat-radio-button>
                <mat-form-field class="ml-2" appearance="outline">
                    <mat-label>選擇監護人，如：祖父</mat-label>
                    <mat-select [formControlName]="'guardian'">
                        <mat-option [value]="'祖父'">祖父</mat-option>
                        <mat-option [value]="'祖母'">祖母</mat-option>
                        <mat-option [value]="'外祖父'">外祖父</mat-option>
                        <mat-option [value]="'外祖母'">外祖母</mat-option>
                        <mat-option [value]="'其他'">其他</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-radio-group>
            <h5 class="pstitle mb-2">請選擇綁定學生之學層</h5>
            <mat-radio-group class="d-flex flex-column" color="primary" [formControlName]="'level'">
                <mat-radio-button value="國小">國小</mat-radio-button>
                <mat-radio-button class="mt-2" value="國中">國中</mat-radio-button>
                <mat-radio-button class="mt-2" value="高中">高中</mat-radio-button>
                <mat-radio-button class="mt-2" value="高職">高職</mat-radio-button>
                <mat-radio-button class="mt-2" value="特教">特教</mat-radio-button>
            </mat-radio-group>
            <h5 class="pstitle mt-4">請輸入綁定學生身分證字號</h5>
            <div class="form-group">
                <input type="text" class="form-control" placeholder="身分證統一編號" [formControlName]="'idNumber'">
                <p class="font-weight-bold" *ngIf="isLoading">資料查詢中</p>
                <mat-error *ngIf="!studentValid">{{errorMsg}}</mat-error>
            </div>
            <div class="step-action">
                <button type="button" class="btn btn-custom btn-lg"
                    [disabled]="studentGroup.disabled || !studentGroup.valid || isLoading" 
                    (click)="checkPCbinding()">
                    驗證
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="studentValid">
            <h5 class="pstitle">請選擇綁定學生所在學校</h5>
            <mat-radio-group class="d-flex flex-column" color="primary" [formControl]="selectedSchool">
                <mat-radio-button *ngFor="let school of schoolList" [value]="school.SchoolCode">
                    {{school.SchoolName}}
                </mat-radio-button>
            </mat-radio-group>
            <mat-error>{{confirmErrorMsg}}</mat-error>
            <p class="text-danger" *ngIf="validErrorTime">
                就讀學校錯誤，請注意，基於安全性考量，再次錯誤將強制退出。
            </p>
            <div class="step-action">
                <button type="button" 
                    class="btn btn-custom btn-lg"
                    [disabled]="selectedSchool.invalid"
                    (click)="confirm()">
                    下一步
                </button>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'finish'">
        <h5 class="pstitle mt-2">請確認綁定學生資訊</h5>
        <div class="pbox mt-2" *ngFor="let child of appComponent.tempList">
            <div class="pbox-del">
                <button mat-icon-button class="text-black-50" (click)="removeChild(child)">
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </div>
            <div><span class="pbox-titl">姓名：</span>{{child.child_name}}</div>
            <div><span class="pbox-titl">班級：</span>{{child.class_name}}</div>
            <div><span class="pbox-titl">稱謂：</span>{{child.legal_represent || child.guardian}}</div>
        </div>
        <div class="pbox d-flex justify-content-center mt-2">
            <button mat-button color="primary" (click)="newPCBind()">
                <mat-icon>add_circle_outline</mat-icon>
                新增綁定學生
            </button>
        </div>
        <div class="step-action">
            <button type="button" 
                class="mt-5 btn btn-custom btn-lg"
                [disabled]="appComponent.tempList.length<=0"
                (click)="nextStep()">
                下一步
            </button>
        </div>
    </ng-container>
</ng-container>