import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PcbindService {

  constructor(
    private http: HttpClient
  ) { }

  public async checkEmail(email: string) {
    return this.http.get(`/api/ldap/check_email?email=${email}`).toPromise();
  }

  public async checkIDNumber(idNumber: string) {
    return this.http.get(`/api/ldap/check_id_number?id_number=${idNumber}`).toPromise();
  }

  public async getSchools(idNumber: string, level: string) {
    return this.http.get(`/api/imdb/get_schools?id_number=${idNumber}&level=${level}`).toPromise();
  }

  public async bindChildren(data: any) {
    return this.http.post('/api/imdb/bind_children', {data}).toPromise();
  }

  public async getRelationships() {
    return this.http.get('/api/imdb/get_relationships').toPromise();
  }

  /** 透過家長 idNumber 查詢中介親子綁定申請紀錄 */
  public async checkParentApplys(parentIDNumber: string) {
    return this.http.post(`/api/imdb/check_parent_applys`, {parentIDNumber}).toPromise();
  }

  /** 透過學生 idNumber 查詢中介親子綁定申請紀錄 */
  public async checkChildrenApplys(id_number: string) {
    return this.http.post(`/api/imdb/check_children_applys`, {id_number}).toPromise();
  }

  public async sendApplication(name: string, idNumber: string, email: string, phone: string) {
    return this.http.post('/api/imdb/send_application', {name, idNumber, email, phone}).toPromise();
  }

  public async sendSMSCode(phone: string, step: number=null) {
    return this.http.get(`/service/send_sms_code?phone=${phone}&step=${step}`).toPromise();
  }

  public async sendEmailCode(email: string) {
    return this.http.get(`/service/send_email_code?email=${email}`).toPromise();
  }

  public async checkSMSCode(phone: string, code: string, step: number) {
    return this.http.post('/service/check_sms_code', {phone, code, step}).toPromise();
  }

  public async checkEmailCode(email: string, code: string, step: number) {
    return this.http.post('/service/check_email_code', {email, code, step}).toPromise();
  }

  public async removeChild(id: number) {
    return  this.http.post('/service/remove_child', { id }).toPromise();
  }

  public async getAgreement() {
    return this.http.get(`/service/get_agreement`).toPromise();
  }

  public async setAgreement() {
    return this.http.post('/service/set_agreement', {}).toPromise();
  }

  public async getPCbing(phone: string, email: string) {
    return this.http.get(`/service/get_pc_bind?phone=${phone}&email=${email}`).toPromise();
  }

  public async getLatestInfoByIDNumber(idNumber: string) {
    return this.http.get(`/service/get_latest_info_by_idnumber?idNumber=${idNumber}`).toPromise();
  }

}