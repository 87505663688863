<div *ngIf="!isApp()"
     class="titletop d-flex">
    <div class="d-flex align-items-center">
        <div class="d-flex align-items-center ml-4 mr-auto">
            <img class="cologo"
                 src="../assets/img/logos.png"
                 alt="酷課雲 Logo" />
            <span class="coheader align-self-center ml-2"
                  style="word-break: keep-all;">親子綁定</span>
        </div>
    </div>
</div>
<hr class="mt-0 cohr">
<div class="step-content">
    <section class="container"
             style="padding-bottom: 25px;">
        <p class="mt-3"
           class="text-custom">感謝您申請臺北市單一身分驗證親子帳號</p>
        <div class="d-flex flex-row justify-content-between align-items-center mb-4">
            <h5 class="m-0"
                style="font-weight: 600;">目前申請進度</h5>
            <button class="text-secondary"
                    mat-button
                    (click)="reload()">
                <span class="material-icons">refresh</span>
                重新載入
            </button>
        </div>
        <div class="pbox">
            <ng-container *ngIf="!isLoading;else tplLoading">
                <div class="row m-1 mr-4 d-flex flex-row justify-content-between mb-3"
                     *ngFor="let child of childList">
                    <div>
                        <span class="pbox-titl mr-4">學生</span>
                        <span>{{child.name}}</span>
                    </div>
                    <div class="position-relative">
                        <span>{{child.status}}</span>
                        <a *ngIf="child.status=='申請'"
                           style="top: -2px;right: -30px;cursor: pointer;"
                           class="position-absolute text-danger"
                           (click)="deleteOne(child.id_number)">
                            <span class="material-icons">delete_forever</span>
                        </a>
                    </div>
                </div>
            </ng-container>
            <div class="d-flex flex-row justify-content-center">
                <button mat-button
                        color="primary"
                        [routerLink]="['/apply/2']">
                    <mat-icon>add_circle_outline</mat-icon>
                    新增綁定學生
                </button>
            </div>
        </div>
        <mat-error *ngIf="isError">{{errMsg}}</mat-error>
        <div class="mx-3 mt-2">
            <span>移除所有申請中以及不通過之紀錄，請點：</span>
            <a class="beautiful-color"
               href="javascript:void(0);"
               (click)="restartAll()">
                註銷全部紀錄</a>
            <span>。</span>
        </div>
        <h5 class="pstitle mt-4 mb-4">申請流程說明</h5>
        <div class="desc"
             style="margin: 4px 0;">
            <img src="../../assets/img/Send.png"
                 alt="">
            <div style="margin-left: 18px;">
                <span>送出線上申請資料。</span>
                <span *ngIf="!hasApply()"
                      class="text-mute">
                    下載留存
                </span>
                <a *ngIf="hasApply()"
                   class="beautiful-color"
                   href="/service/file/download/agreement"
                   download="臺北市單一身分驗證親子帳號申請個資授權同意書.pdf">
                    下載留存
                </a>
            </div>
        </div>
        <div class="vline"></div>
        <div class="desc"
             style="margin: -12px 0;">
            <img src="../../assets/img/ok3.svg"
                 alt="">
            <div style="margin-left: 18px;">
                <span>導師審核確認後綁定成功</span>
                <p class="lb-description">
                    帳號建立將以電子郵件通知，收到請立即登入系統，查看親子綁定狀態或新增綁定。
                </p>
            </div>
        </div>
        <div class="vline"></div>
        <div class="desc"
             style="margin: -12px 0;">
            <img src="../../assets/img/ok4.svg"
                 alt=""
                 style="margin-top: 20px; align-self: baseline;">
            <div style="margin-left: 18px;">
                <span>登入系統</span>
                <p class="lb-description">
                    預設帳號為申請信箱，密碼為身分證後六碼，第一次登入需修改密碼。<br>
                    <span class="beautiful-color"
                          style="cursor: pointer;"
                          (click)="showMore=!showMore">
                        {{showMore ? '臺北市教職員使用單一身份驗證服務，請依自訂帳號登入系統。系統預設帳號為單位管理者建立，如未曾開通帳號，請洽學校資訊組長。'
                        : '了解臺北市教職員登入方式。'}}
                    </span>
                </p>
            </div>
        </div>
        <!-- 
        <div class="d-flex flex-row justify-content-between align-items-center mt-3">
            <button class="text-secondary ml-auto"
                    mat-button
                    (click)="restartAll()">
                <span class="material-icons">delete</span>
                註銷申請紀錄
            </button>
        </div> -->

        <div class="notice">
            <img src="../../assets/img/notice.svg"
                 alt="">
            <div style="margin-left: 8px;">
                可使用填寫的手機或信箱再次驗證進入本服務查詢狀態。
            </div>
        </div>
        <div class="w-100 text-center mt-3">
            <button role="button"
                    class="btn btn-custom btn-lg ng-star-inserted"
                    (click)="close()">
                登出服務
            </button>
        </div>
        <!-- <div class="notice" style="margin-top: 40px;">
            <img src="../../assets/img/notice.svg" alt="">
            <div style="margin-left: 8px;">
                帳號為您申請時填寫的個人電子信箱，密碼預設為身分證後六碼。登入後可查詢申請進度，以及新增綁定學生。
            </div>
        </div>
        <div class="step-action mb-4">
            <button type="button" class="btn btn-custom btn-lg">
                立即登入
            </button>
        </div> -->
    </section>
</div>

<ng-template #tplLoading>
    <h6 class="text-center">中介資料載入中...</h6>
</ng-template>